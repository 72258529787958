
body{
    background:#C6EAF4 url(../img/calm_blue.jpg) 45% 0% no-repeat;
    /* background-size:330%; */
    background-size: cover;
    text-align:center;
    font-family:Helvetica;
    color:#1580AE;
    /* min-height:500px;
    min-width:100%;
    height:100vh; */
    margin:0;
    /* padding:0 0 125px; */
    position:relative;
}

#splashScreen{
    position:absolute;
    top:50%; left:50%; /* position of top left of box */
    transform:translate(-50%, -50%); /* relative to size wxh of box */

    width: 200px;
    height:200px;

    background:url("../img/logo_side.png") 50% no-repeat;
    background-size:contain;

    text-indent:-5000px;
}

