.icon-wrapper {
    position: relative;
    padding: 0px 30px;
}

/* .icon-wrapper .anticon {
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 16px;
    line-height: 1;
} */

.icon-wrapper .icon-wrapper-active {
    color: rgba(0, 0, 0, 0.45);
}

.icon-wrapper .ant-btn:first-child {
    left: 0;
    position: absolute;
    top: -10px;
}

.icon-wrapper .redo {
    right: 0;
    position: absolute;
    top: -10px;
}

.icon-wrapper .ant-slider {
    margin-left: 30px;
    top: 4px;
     margin-right: 30px;
}

.media-wrapper {
    width: 400px;
    height: 400px;
}

/* .media-controller{
    width: 100%;
    position: absolute;
    bottom: 0;
} */

.ant-card-bordered {
    margin:auto 15px;
}

.ant-card-body {
    padding: 10px !important;
}

.react-player__preview{
    background-color: black;
}

.react-player__shadow{
    background-color: grey;
}

.play_text {
    
}

.media-controller {
    margin-bottom:30px !important;
}


.repeat_icon{
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: -5px
}
.repeat_icon i{
    display:block;
    font-style:normal;
    font-size:77%;
    line-height: 100%;
}