/* #bgs{
    position:fixed;
    bottom:0;
    text-align:center;
    width:100%;
    padding:10px 0;
    border-top:1px solid #666;
    transition: bottom .25s ease-in-out;
} */

#bgs.open{
    bottom:0;
}
#bgs:after{
    content:"";
    z-index:-1;
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    opacity:.6;
    background:#ccc;
}
/* #bgs .handle{
    position: absolute;
    top: -31px;
    left: 50%;
    margin-left: -85px;
    width: 170px;
    content: "";
    border: 1px solid #666;
    border-radius:5px 5px 0 0;
    border-bottom:none;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    text-decoration:none;
    color:#333;
} */
#bgs .handle:after{
    content:"";
    z-index:-1;
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    opacity:.6;
    background:#ccc;
}
#bgs .bg{
    display:inline-block;
    width:80px;
    height:80px;
    border:1px solid #C6EAF4;
    /* margin:0 5px; */
}
a.waterfall{
    background:#C6EAF4 url(../img/waterfall.gif) 0% 0% no-repeat;
    background-size:cover;
}

a.fire{
    background:#C6EAF4 url(../img/fire.gif) 0% 0% no-repeat;
    background-size:cover;
}
a.stillblue{
    background:#C6EAF4 url(../img/calm_blue.jpg) 0% 0% no-repeat;
    background-size:cover;
}
a.winter{
    background:#C6EAF4 url(../img/winter.gif) 0% 0% no-repeat;
    background-size:cover;
}
a.night{
    background: #C6EAF4 url(../img/nightwater2.gif) 0% 0% no-repeat;
    background-size:cover;
}
a.norway{
    background:#C6EAF4 url(../img/norway.gif) 0% 0% no-repeat;
    background-size:cover;
}
a.beach{
    background: #C6EAF4 url(../img/tropicalbeach.gif) 0% 0% no-repeat;
    background-size:cover;
}


.ant-drawer-body {
    padding: 10px !important;
}

/* .ant-drawer-content-wrapper::after {
    opacity: 0.5 !important;
    content: ''

} */

.MediaPositioning {
    margin-top: auto;
}

#main {
    height: 100%;
    display: flex;
    flex-direction: column;
}