.view_box{
    text-align:center;
}
/* .view_body{
    margin:0 auto;
    max-width:86vw;
} */

.player {
    position:relative;
}

.transparent {
    opacity: 0.7;
}

.visible {
    opacity: 1.0;
}

.titleBar{
    position:relative;
}

.titleBar:before {
    content:"";
    position:absolute;
    left:0; top:0;
    height:84px;
    width:100vw;
    z-index:0;
    background:#fff;
    opacity:.65;
}

.titleBar.playing:before{
    display:none;
}

.titleBar.playing .AppTitle h2,
.titleBar.playing .AppTitle h3{
    text-shadow:1px 1px 0px #333;
}

.AppTitle h2, .AppTitle h3{
    font-kerning: auto;
    margin:0;
    color:#6FACA8
}

.AppTitle h2 {
    margin:8px auto 0;
    text-transform:uppercase;
    color: #4B7FAB;
}

.AppTitle h3{
    font-weight:normal;
    position: absolute;
    left: 86px;
    top: 50px;
    font-size:140%;
}

a.waterfall{
    background:#C6EAF4 url(../img/waterfall.gif) 0% 0% no-repeat;
    background-size:cover;
}

.MediaPositioning {
    margin-top: 20%;
}
@media (max-width: 768px) {
    .MediaPositioning {
      margin-top:auto;
    }
  }
#main {
    height: 100%;
    display: flex;
    flex-direction: column;
}


.project_login {
    position:absolute;
    top:90px;
    left:5px;
    width:66px;
    height: 30px;
    text-align:center;
}

.project_login.in:after,
.project_login.out:after{
    content : "Sign In";
    position:absolute;
    bottom:-20px;
    left:0;
    width:100%;
    text-align:center;
    font-size:85%;
    color:#333;
    text-shadow: 1px 1px 3px #666;
}

.project_login.out:after{
    content : "Sign Out";
}

.user_name {
    float:left;
    vertical-align:bottom;
    color:#333;
    fontSize:85%;
}