
@media (min-width: 768px) {
    .offline.ant-drawer-content{
        width: 50% !important;
        left: 25% !important;
        border-radius: 25px;
        position: relative;
        box-shadow: none;
    }
    .installprompt.ant-drawer-content{
        width: 50% !important;
        /* left: 25% !important; */
        border-radius: 25px;
        position: relative;
    }
    /* .installprompt.ant-drawer-content-wrapper{
        box-shadow: none;
    } */
    .ant-drawer-content-wrapper{
        box-shadow: none !important;
    }
}