.install_modal .modal-dialog {
    margin-top: 100px;
}

.pwa_install {
    max-height:70vh;
    overflow:scroll;
}
.pwa_install p {
    margin-bottom:25px;
}

.pwa_install_btn{
    position:absolute;
    top:90px; right:5px;
    width:68px;
    height:34px;
    text-align:center;
}
.pwa_install_btn:after {
    content:"Install App";
    position:absolute;
    bottom:-20px;
    left:0;
    width:100%;
    color:#333;
    font-size:85%;
    text-shadow:1px 1px 0px #666
}

.pwa_install_btn .login,
.pwa_install_btn .install_instructions{
    display:inline-block;
    width:34px;
    height:34px;
    cursor:pointer;
    color:#333;
    text-indent:-5000px;
}

.pwa_install_btn .login {
    display:none;
}

.pwa_install li span {
    display:block;
    padding:0;
    margin:0 0 10px;
    color:#333;
    font-size:92%;
}

.modal_close_x_btn{
    font-size:140%;
    cursor:pointer;
}





.android.pwa_install dl {
    margin-top:30px;
    padding:10px 20px;
    overflow:hidden;
}

.android.pwa_install dt {
    float:left;
    width:50px;
    margin-right:10px;
}

.android.pwa_install dt img {
    max-width:50px;
}

.android.pwa_install dd {
    float:left;
}

.android.pwa_install h5 {
    margin:0;
}

.android.pwa_install p {
    margin:0;
}

.android.pwa_install .add_to_home{
    text-align:right;
    float:none;
}

.android.pwa_install .add_to_home button{
    margin-top:20px;
}